#cta {
  scroll-margin-top: 13rem; //9
}
.section-cta-parkings {
  padding: 3.6rem 0 6.4rem 0;
  // padding-bottom: 0;
  .cta-container {
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .cta {
    display: grid;
    grid-template-columns: 2fr 1fr;
    /* background-color: #76abdf; */
    box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.175);
    border-radius: 3.6rem;
    background-image: linear-gradient(to right bottom, #adcdec, #76abdf);
    overflow: hidden;
  }

  .cta-text-box {
    padding: 4.8rem 6.4rem 6.4rem 6.4rem;
    color: #f1f7fc;
  }

  .cta .heading-secondary {
    color: #f1f7fc;
    margin-bottom: 3.2rem;
  }

  .cta-text {
    font-size: 2.4rem;
    line-height: 1.8;
    margin-bottom: 4.8rem;
  }

  .cta-img-box {
    background-image: linear-gradient(
        to right bottom,
        rgb(173, 205, 236, 0.5),
        rgb(118, 171, 223, 0.5)
      ),
      url("../assets/webp/parking_lot_areial.webp");
    background-size: cover;
    background-position: bottom;
  }

  .cta-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3.2rem;
    row-gap: 2.4rem;
    color: #233343;
    .btn--form {
      background-color: #233343;
      color: #f1f7fc;
      align-self: end;
      // padding: 1.2rem;
      border: none;
      transition: all 0.3s;
      font-size: 2rem;
      margin-top: 2.8rem;
      @media (min-width: 60em) {
        grid-column: 1/3;
      }
      &:hover {
        background-color: #f1f7fc;
        box-shadow: none;
        color: #233343;
      }
    }
  }
  //   Primary: #76abdf
  //  - Tints: #91bce5 #adcdec #d6e6f5 #f1f7fc
  //  - Shades: #0c1116 #233343 #476786 #5e89b2

  .cta-form label {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
    padding-right: 1.4rem;
  }
  .cta-form input,
  .cta-form select {
    width: 100%;
    padding: 1.2rem 2rem;
    font-size: 1.8rem;
    font-family: inherit;
    border: none;
    color: inherit;
    background-color: #f1f7fc;
    border-radius: 40px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .cta-form input::placeholder {
    color: #aaa;
  }

  .cta * {
    transition: box-shadow 0.3s;
  }

  .cta *:focus {
    outline: none;
    box-shadow: 0 0 0 0.8rem rgb(241, 247, 252, 0.5);
  }

  .car-svg {
    transform: translate(-400%, 113%);
    @media (max-width: 110em) {
      transform: translate(-200%, 113%);
    }
    @media (max-width: 43em) {
      transform: translate(-100%, 113%);
    }
  }
}

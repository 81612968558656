#stress {
  scroll-margin-top: 9.5rem;
}
.section-stress {
  background-color: #f1f7fc;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  min-height: 50rem;
  overflow: visible;
  margin-bottom: 9.6rem;
  // height: 50rem;
  // border-top: 2px solid #f1f7fc;
  @media screen and (max-width: 60em) {
    // height: 50rem;
    display: flex;
    flex-direction: column;
  }

  .stress-container {
    padding: 4.8rem 6.4rem;
    // padding-right: 6.4rem;
  }

  .subheading {
    opacity: 0;
    transform: translateX(-20%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateX(0);
      // animation: slideIn 1s ease-in;
    }
  }

  .heading-secondary {
    margin-bottom: 3.6rem !important;
    opacity: 0;
    transform: translateX(-20%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateX(0);
      // animation: slideIn 1s ease-in;
    }
  }

  .stresss {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 4.8rem;
    column-gap: 8rem;
  }

  .stress-img {
    width: 6.4rem;
    border-radius: 50%;
    margin-bottom: 1.2rem;
  }

  .stress-text {
    font-size: 1.8rem;
    line-height: 1.8;
    margin-bottom: 1.6rem;
    color: #333;
    opacity: 0;
    transform: translateX(-20%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateX(0);
      // animation: slideIn 1s ease-in;
    }
  }
  .stress-name {
    font-size: 1.6rem;
    color: #6f6f6f;
  }

  .gallery-item img:hover {
    transform: scale(1.1);
  }
  .handshake-img-container {
    // height: 50rem;
    height: 100%;
    min-height: 50rem;
    width: 100%;
    opacity: 0;
    transition: opacity 1s;
    &.animate {
      opacity: 1;
    }
    .parralax-stress {
      height: 100%;
      width: 100%;
      @media screen and (max-width: 60em) {
        display: none;
      }
      .react-parallax-content {
        height: 100%;
        width: 100%;
        .parralax-stress-image {
          height: 100%;
          width: 100%;
          min-height: 50rem;
          object-fit: contain;
        }
      }
    }
    .handshake-img {
      height: 100%;
      width: 100%;
      min-height: 50rem;
      // height: 100%;
      background-image: linear-gradient(
          to right bottom,
          rgb(173, 205, 236, 0.3),
          rgb(118, 171, 223, 0.3)
        ),
        url("../assets/webp/stress.webp");
      background-size: cover;
      background-position: top;
      display: none;
      @media screen and (max-width: 60em) {
        display: block;
      }
    }
  }
}

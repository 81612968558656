#advantages {
  scroll-margin-top: 5rem;
}
.section-advantages {
  padding: 9.6rem 0;
  // background-color: #f1f7fc;
  & *:focus {
    outline: none;
    box-shadow: none;
  }

  .heading-secondary {
    margin-bottom: 3.6rem !important;
  }

  .advantage {
    box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
    border-radius: 1.1rem;
    overflow: hidden;
    position: relative;
    transition: all 0.4s;
  }
  .title-contanier-advantages {
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .advantage:hover {
    transform: translate(0, -1.2rem);
    box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.06);
  }
  .container-advantage {
    max-width: 150rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.4rem;
    padding: 0 2.4rem;
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateY(0);
    }
    @media (max-width: 25em) {
      flex-direction: column;
    }
    .break {
      display: none;
    }
    @media (max-width: 69em) {
      .break {
        display: block;
        flex-basis: 100%;
        height: 0;
      }
    }
    .advantage-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.4rem;
      color: #fff;
      background-color: #adcdec;
      border-radius: 40px;
      width: 25rem;
      height: 25rem;
      @media (max-width: 25em) {
        width: 30rem;
        height: 30rem;
      }

      &.back {
        padding: 1.4rem;
      }

      .advantage-icon {
        height: 10rem;
        width: 10rem;
        color: #fff;
      }
      .advantage-text {
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        @media (max-width: 25em) {
          font-size: 2.4rem;
        }
        &.card-text-front {
          font-size: 2rem;
          @media (max-width: 25em) {
            font-size: 2.4rem;
          }
        }
        &.card-text-back {
          font-size: 1.6rem;
          @media (max-width: 25em) {
            font-size: 2rem;
          }
        }
      }
      .double-parking {
        height: 10rem;
        // width: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .advantage-icon {
          height: 7rem;
          width: 7rem;
        }
      }
    }
  }

  .advantage-content {
    /* padding: 3.2rem 4.8rem 4.8rem 4.8rem; */
    padding: 3.2rem;
  }

  .advantage-tags {
    margin-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: absolute;
    top: 3%;
    left: 5%;
    transform: translate(-5%, -5%);
  }
  .tag {
    display: inline-block;
    padding: 0.4rem 0.8rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #333;
    border-radius: 100px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tag--vegeterian {
    background-color: #51cf66;
  }
  .tag--vegan {
    background-color: #94d82d;
  }
  .tag--paleo {
    background-color: #ffd43b;
  }

  .tag-icon {
    margin-right: 0.3rem;
  }

  .advantage-title {
    font-size: 2.4rem;
    color: #333;
    font-weight: 600;
    margin-bottom: 3.2rem;
  }

  .advantage-attributes {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .advantage-attribute {
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  .advantage-icon {
    height: 2.4rem;
    width: 2.4rem;
    color: #76abdf;
  }

  .advantage-img {
    width: 100%;
  }

  .all-recipes {
    text-align: center;
    font-size: 1.8rem;
  }
}

#about {
  scroll-margin-top: 4.8rem;
}
.section-about {
  // background-color: #fff;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // align-items: center;
  // min-height: 50rem;
  // overflow: visible;
  // // height: 50rem;
  // // border-top: 2px solid #f1f7fc;
  // @media screen and (max-width: 60em) {
  //   // height: 50rem;
  //   display: flex;
  //   flex-direction: column;
  // }
  // max-width: 200rem;
  // background-image: url(../assets/bg/llline-4.svg),
  //   url(../assets/bg/llline-6.svg);
  background-image: url(../assets/bg/llline-9.svg),
    url(../assets/bg/llline-7.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .about-container {
    // background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1.5fr;

    align-items: center;
    justify-items: center;
    padding: 3.6rem 3.6rem 6.4rem 3.6rem;
    // min-height: 100rem;
    // overflow: hidden;
    // display: flex;
    // flex-direction: column;

    // height: 50rem;
    // border-top: 2px solid #f1f7fc;
    @media screen and (min-width: 135em) {
      padding-bottom: 16.4rem;
    }
    @media screen and (max-width: 60em) {
      // height: 50rem;
      display: flex;
      flex-direction: column;
    }
    max-width: 150rem;
  }
  .about-text-container {
    padding: 4.8rem 3.6rem;
    text-align: center;
    max-width: 100rem;
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .heading-secondary {
    margin-bottom: 3.6rem !important;
  }

  .about-img {
    width: 6.4rem;
    border-radius: 50%;
    margin-bottom: 1.2rem;
  }

  .about-text {
    font-size: 1.8rem;
    line-height: 1.8;
    margin-bottom: 1.6rem;
    color: #333;
  }
  .about-name {
    font-size: 1.6rem;
    color: #6f6f6f;
  }
  .youtube-lazy {
    height: 100%;
    width: 100%;
    max-width: 96rem;
    max-height: 55rem;
    overflow: hidden;
    aspect-ratio: 15.8/9;
    border-radius: 2.4rem;
    box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.175);
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateY(0);
    }
    .lazyload-wrapper {
      height: 100%;
      width: 100%;
      max-width: 96rem;
      max-height: 55rem;
      overflow: hidden;
      aspect-ratio: 15.8/9;
    }
  }
  .video-container {
    // max-width: 120rem;
    // width: 100%;
    // height: 100%;
    background-color: #fff;

    box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.175);
    border-radius: 3.6rem;
  }
  .about-img-container {
    box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.175);
    border-radius: 3.6rem;
    height: fit-content;
    background-color: transparent;
    img {
      border-radius: 3.6rem;
      max-width: 100rem;
      width: 100%;
    }
  }
}

#time-slots {
  scroll-margin-top: 4.8rem;
}
.section-time-slots {
  // background-color: #fff;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // align-items: center;
  // min-height: 50rem;
  // overflow: visible;
  // // height: 50rem;
  // // border-top: 2px solid #f1f7fc;
  // @media screen and (max-width: 60em) {
  //   // height: 50rem;
  //   display: flex;
  //   flex-direction: column;
  // }
  // max-width: 200rem;
  // background-image: url(../assets/bg/llline-4.svg),
  //   url(../assets/bg/llline-6.svg);
  // background-image: url(../assets/bg/llline-9.svg),
  //   url(../assets/bg/llline-7.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .time-slots-container {
    // background-color: #fff;
    display: grid;
    // grid-template-columns: 1fr;
    // grid-template-rows: 1fr 1.5fr;
    grid-template-columns: 1fr 2fr;

    align-items: center;
    justify-items: center;
    padding: 3.6rem 3.6rem 11.2rem 3.6rem;
    // padding-bottom: 4.8rem;
    // min-height: 100rem;
    // overflow: hidden;
    display: flex;
    flex-direction: row;
    gap: 3.6rem;

    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateY(0);
      // animation: slideIn 1s ease-in;
    }

    // height: 50rem;
    // border-top: 2px solid #f1f7fc;
    @media screen and (min-width: 135em) {
      padding-bottom: 16.4rem;
    }
    @media screen and (max-width: 60em) {
      // height: 50rem;
      display: flex;
      flex-direction: column;
    }
    max-width: 120rem;
  }
  .time-slots-text-container {
    padding: 4.8rem 3.6rem;
    text-align: center;
    max-width: 100rem;
  }

  .heading-secondary {
    margin-bottom: 3.6rem !important;
  }

  // .time-slots-img {
  //   width: 6.4rem;
  //   border-radius: 50%;
  //   margin-bottom: 1.2rem;
  // }

  .time-slots-text {
    font-size: 1.8rem;
    line-height: 1.8;
    margin-bottom: 1.6rem;
    color: #333;
  }

  .time-slots-name {
    font-size: 1.6rem;
    color: #6f6f6f;
  }
  .lazy-time-slots {
    max-width: 35rem;
    width: 100%;
    .time-slots-img {
      max-width: 35rem;
      width: 100%;
    }
  }
}

/*************************************/
/* GENERAL COMPONENTS */
/*************************************/

.landing-route {
  // font-family: "SimplerPro", "Arial", Helvetica, sans-serif;
  // font-family: "Fredoka", sans-serif;
  // font-family: "Kanit", sans-serif;
  // font-family: "Montserrat", sans-serif;
  // font-family: "Roboto", sans-serif;
  // font-family: "Rubik", sans-serif;
  font-family: "Secular One", sans-serif;
  // font-family: "Suez One", serif;
  // font-family: "Varela Round", sans-serif;
  // a {
  // font-family: "SimplerPro", "Arial", Helvetica, sans-serif;
  // }
  // span {
  //   font-family: "SimplerPro", "Arial", Helvetica, sans-serif;
  // }
  // p {
  //   font-family: "SimplerPro", "Arial", Helvetica, sans-serif;
  // }
  // div {
  //   font-family: "SimplerPro", "Arial", Helvetica, sans-serif;
  // }
  // h1 {
  //   font-family: "SimplerPro", "Arial", Helvetica, sans-serif;
  // }
  // h2 {
  //   font-family: "SimplerPro", "Arial", Helvetica, sans-serif;
  // }
  // h3 {
  //   font-family: "SimplerPro", "Arial", Helvetica, sans-serif;
  // }
  // h4 {
  //   font-family: "SimplerPro", "Arial", Helvetica, sans-serif;
  // }

  .container {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 3.2rem;
  }

  .grid {
    display: grid;
    column-gap: 6.4rem;
    row-gap: 9.6rem;
  }

  .grid:not(:last-child) {
    margin-bottom: 9.6rem;
  }

  .grid--2-cols {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid--3-cols {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid--4-cols {
    grid-template-columns: repeat(4, 1fr);
  }
  .grid--5-cols {
    grid-template-columns: repeat(5, 1fr);
  }

  .grid--center-v {
    align-items: center;
  }
  .grid--center-h {
    justify-content: center;
  }

  .heading-primary,
  .heading-secondary,
  .heading-tertiary {
    font-weight: 700;
    // color: #333;
    letter-spacing: -0.5px;
  }

  .heading-primary {
    font-size: 5.2rem;
    line-height: 1.05;
    margin-bottom: 3.2rem;
  }

  .heading-secondary {
    font-size: 4.4rem;
    line-height: 1.2;
    margin-bottom: 9.6rem;
  }

  .heading-tertiary {
    font-size: 3rem;
    line-height: 1.2;
    margin-bottom: 3.2rem;
  }

  .subheading {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    color: #76abdf;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    letter-spacing: 0.75px;
  }

  .btn,
  .btn:link,
  .btn:visited {
    display: inline-block;
    text-decoration: none;
    font-size: 2rem;
    padding: 1.6rem 3.2rem;
    font-weight: 600;
    border-radius: 9px;
    /* transition: background-color 300ms; */
    transition: all 300ms;

    /* Only neccesary for .btn */
    border: none;
    cursor: pointer;
    font-family: inherit;
  }

  .btn--full:link,
  .btn--full:visited {
    background-color: #76abdf;
    color: #fff;
  }
  .btn--full:hover,
  .btn--full:active {
    background-color: #76abdf;
  }

  .btn--outline:link,
  .btn--outline:visited {
    background-color: #fff;
    color: #555;
  }
  .btn--outline:hover,
  .btn--outline:active {
    background-color: #f1f7fc;
    box-shadow: inset 0 0 0 3px #fff;
  }

  .btn--form {
    background-color: #233343;
    color: #f1f7fc;
    align-self: end;
    padding: 1.2rem;
  }

  .btn--form:hover {
    background-color: #fff;
    color: #233343;
  }

  .link:link,
  .link:visited {
    display: inline-block;
    color: #76abdf;
    text-decoration: none;
    border-bottom: 1px solid currentColor;
    padding: 0.2rem;
    transition: all 0.3s;
  }
  .link:hover,
  .link:active {
    color: #76abdf;
    border-bottom: 1px solid transparent;
  }

  .list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .list-item {
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    line-height: 1.2;
  }

  .list-item--x {
    color: #aaa;
  }

  .list-icon {
    width: 3rem;
    height: 3rem;
    color: #76abdf;
  }

  .list-icon--x {
    color: #aaa;
  }

  *:focus {
    outline: none;
    /* outline: 4px dotted #76abdf;
      outline-offset: 8px; */
    box-shadow: 0 0 0 0.8rem rgb(118, 171, 223, 0.5);
  }

  /*************************************/
  /* HELPERS */
  /*************************************/

  .center-text {
    text-align: center;
  }

  .margin-right-sm {
    margin-right: 1.6rem !important;
  }
  .margin-bottom-md {
    margin-bottom: 4.8rem !important;
  }

  strong {
    font-weight: 500;
  }

  .hidden {
    display: none;
  }
}

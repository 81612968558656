.section-hero-desks {
  // background-color: #f1f7fc;
  background-image: linear-gradient(
      rgb(118, 171, 223, 0.8),
      rgb(118, 171, 223, 0.8)
    ),
    // url("../../assets/parking_areial_wide.png");
    url("../assets/webp/office-3.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;

  // padding: 12.8rem 4.8rem 9.6rem 4.8rem;
  padding: 9.6rem 4.8rem 4.8rem 4.8rem;
  // padding: 4.8rem 4.8rem 9.6rem 4.8rem;
  color: #fff;
  height: 80vh;
  min-height: 77rem;

  display: flex;
  align-items: center;
  justify-content: center;
  // &.sticky-margin {
  //   margin-top: 9.6rem;
  // }

  .hero {
    max-width: 130rem;
    display: flex;
    justify-content: center;
    // grid-template-columns: 1fr 1fr;
    // margin: 0 auto;
    /* padding: 0 3.2rem; */
    align-items: center;
    gap: 9.6rem;
    text-align: center;
    max-width: 90rem;
    opacity: 0;
    transition: opacity 1s;
    &.animate {
      opacity: 1;
    }
  }

  .hero-heading {
    letter-spacing: 1.5px;
    color: #fff;
    font-size: 6.4rem !important;
    margin-bottom: 6.4rem;
    @media (max-width: 34em) {
      padding: 0 1.2rem;
    }
  }

  .hero-description {
    font-size: 3.6rem;
    // line-height: 1.6;
    margin-bottom: 4.8rem;
    @media (max-width: 25em) {
      font-size: 2.4rem;
    }
    @media (max-width: 34em) {
      padding: 0 1.2rem;
    }
  }

  .hero-text-box {
    .hero-logo {
      // height: 20rem;
      height: 16.4rem;
      padding-bottom: 2.4rem;
    }
    .hero-button-container {
      gap: 2.4rem;
      @media (max-width: 27em) {
        flex-direction: column;
      }
      @media (max-width: 34em) {
        padding: 0 2.4rem;
      }
      .hero-button {
        @media (max-width: 27em) {
          min-width: 24rem;
          height: 6rem;
          font-size: 2.2rem;
        }
        font-size: 1.8rem;
        width: 20rem;
        box-shadow: none;
      }
      .hero-main-btn {
        &:hover {
          background-color: #adcdec;
        }
      }
      .hero-link {
        text-decoration: none;
      }
    }
  }

  .hero-img {
    width: 100%;
  }

  .delivered-advantages {
    display: flex;
    gap: 1.6rem;
    margin-top: 8rem;
    align-items: center;
  }

  .delivered-imgs {
    display: flex;
    align-items: center;
  }

  .delivered-imgs img {
    width: 5.2rem;
    height: 5.2rem;
    border-radius: 50%;
    margin-right: -1.6rem;
    border: 3px solid #f1f7fc;
  }

  .delivered-imgs img:last-child {
    margin: 0;
  }
  .delivered-text {
    font-size: 1.8rem;
    font-weight: 600;
  }
  .delivered-text span {
    color: #76abdf;
    font-weight: 700;
  }
}

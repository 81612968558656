#explain {
  scroll-margin-top: 7rem;
}
.section-explain-desks {
  background-color: #f1f7fc;
  .explain-top-container {
    // display: flex;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    justify-content: center;
    max-width: 150rem;
    margin: 0 auto;
    // gap: 4.8rem;
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // align-items: center;
    min-height: 50rem;
    overflow: visible;

    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateY(0);
    }
    // height: 50rem;
    // border-top: 2px solid #f1f7fc;
    @media screen and (max-width: 43em) {
      // height: 50rem;
      display: flex;
      flex-direction: column;
    }

    .explain-container {
      padding: 4.8rem 3.6rem;
      padding-right: 6.4rem;
    }

    .heading-secondary {
      margin-bottom: 3.6rem;
    }

    .explains {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 4.8rem;
      column-gap: 8rem;
    }

    // .explain-img {
    //   width: 6.4rem;
    //   border-radius: 50%;
    //   margin-bottom: 1.2rem;
    // }

    .explain-text {
      font-size: 1.8rem;
      line-height: 1.8;
      margin-bottom: 1.6rem;
      color: #333;
    }
    .explain-name {
      font-size: 1.6rem;
      color: #6f6f6f;
    }

    .gallery-item img:hover {
      transform: scale(1.1);
    }
    .handshake-img-container {
      // height: 50rem;
      // height: 100%;
      // min-height: 50rem;
      width: 100%;
      .parralax-explain {
        height: 100%;
        width: 100%;
        @media screen and (max-width: 60em) {
          display: none;
        }
        .react-parallax-content {
          height: 100%;
          width: 100%;
          .parralax-explain-image {
            height: 100%;
            width: 100%;
            min-height: 50rem;
            object-fit: contain;
          }
        }
      }
      // display: flex;
      // align-items: center;
      // justify-content: center;
      padding: 6.4rem;
      @media screen and (max-width: 60em) {
        padding: 3.6rem;
      }
      .explain-img {
        // height: 100%;
        // width: 100%;
        margin: 0 auto;
        max-height: 50rem;
        // width: 50rem;
        background-image: linear-gradient(
            to right bottom,
            rgb(173, 205, 236, 0.3),
            rgb(118, 171, 223, 0.3)
          ),
          url("../assets/webp/office-tall-4.webp");
        background-size: cover;
        background-position: center;
        aspect-ratio: 3/4;
      }
    }
  }
}

/* 

1rem = 1em = 16px

In media queries better to use em not rem

BREAK POINTS:
84em = 1344px
75em = 1200px
60em = 960px
49em = 784px
43em = 688px
34em = 544px

*/
// .landing-page {
/*************************************/
/* BELOW 1344px (Smaller desktops) */
/*************************************/
@media (max-width: 84em) {
  html {
    font-size: 60%;
  }

  .heading-primary {
    font-size: 4.4rem !important;
  }

  .gallery {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .main-nav-list {
    gap: 4rem !important;
  }
}

/*************************************/
/* BELOW 1200px (Landscape Tablets) */
/*************************************/

@media (max-width: 75em) {
  /* 1rem = 9px */
  html {
    font-size: 56.25%;
  }

  .grid {
    column-gap: 4.8rem !important;
    row-gap: 6.4rem !important;
  }

  .heading-secondary {
    font-size: 3.6rem !important;
  }
  .heading-tertiary {
    font-size: 3.2rem !important;
  }

  /* .header {
      padding: 0 3.2rem;
    } */

  .main-nav-list {
    gap: 2.4rem !important;
  }

  .hero {
    gap: 4.8rem !important;
  }

  .testimonials-container {
    padding: 9.6rem 3.2rem !important;
  }

  .testimonials {
    grid-template-columns: 1fr !important;
    row-gap: 4.8rem !important;
    column-gap: 8rem !important;
    padding-right: 4.8rem !important;
  }
}

/*************************************/
/* BELOW 960px (Tablets) */
/*************************************/

@media (max-width: 60em) {
  /* 1rem = 8px */
  html {
    font-size: 50%;
    // overflow-x: hidden;
  }

  body {
    // overflow-x: hidden;
  }

  .hero {
    grid-template-columns: 1fr !important;
    padding: 0 8rem !important;
    gap: 6.4rem !important;
  }

  .hero-img {
    width: 60% !important;
  }

  .hero-text-box {
    text-align: center !important;
  }
  .hero-img-box {
    text-align: center !important;
  }

  .delivered-advantages {
    justify-content: center !important;
    margin-top: 3.2rem !important;
  }

  .logos img {
    height: 2.4rem !important;
  }

  .step-number {
    font-size: 7.4rem !important;
  }

  .advantage-content {
    padding: 2.4rem 3.2rem 3.2rem 3.2rem !important;
  }

  .section-testimonials {
    grid-template-columns: 1fr !important;
  }

  .testimonials {
    grid-template-columns: 1fr 1fr !important;
    row-gap: 4.8rem !important;
    column-gap: 8rem !important;
    padding-right: 0 !important;
  }

  .gallery {
    grid-template-columns: repeat(6, 1fr) !important;
  }

  .cta {
    grid-template-columns: 3fr 2fr !important;
  }

  .cta-form {
    display: grid;
    grid-template-columns: 1fr !important;
    column-gap: 3.2rem !important;
    row-gap: 2.4rem !important;
  }

  .btn--form {
    margin-top: 1.2rem !important;
  }

  .pricing-plan {
    width: 80% !important;
  }

  /* Mobile Navigation */
  // .btn-mobile-nav {
  //   display: block !important;
  //   z-index: 9999 !important;
  // }

  .main-nav {
    // background-color: rgba(255, 255, 255, 0.8) !important;
    // -webkit-backdrop-filter: blur(10px) !important;
    // backdrop-filter: blur(10px) !important;
    // position: absolute !important;
    // top: 0 !important;
    // left: 0 !important; // To go back uncomment this
    // // right: 0 !important; // To go back comment this
    // width: 100% !important; // To go back width = 100%
    // height: 100vh !important;
    // transform: translateX(100%) !important; // To go back uncomment this

    // display: flex !important;
    // align-items: center !important;
    // justify-content: center !important;

    // transition: all 0.5s ease-in !important;

    /* 1) Hide visibility */
    /* Does not allow animations */
    /* display: none; */
    opacity: 0 !important;

    /* 2) stop mouse and keyboard */
    pointer-events: none !important;
    /* 3) Hide from screen readers */
    visibility: hidden !important;
  }

  // .nav-open .main-nav {
  //   // width: 100% !important;
  //   opacity: 1 !important;
  //   pointer-events: auto !important;
  //   visibility: visible !important;
  //   transform: translateX(0) !important;
  // }

  // .nav-open .icon-mobile-nav[name="menu-outline"] {
  //   display: none !important;
  // }

  // .nav-open .icon-mobile-nav[name="close-outline"] {
  //   display: block !important;
  //   color: #76abdf !important;
  // }

  // .main-nav-list {
  //   flex-direction: column !important;
  //   gap: 4.8rem !important;
  // }

  // .main-nav-link,
  // .main-nav-link:link,
  // .main-nav-link:visited {
  //   font-size: 3rem !important;
  // }
}

/*************************************/
/* BELOW 784px (Small Tablets) */
/*************************************/

@media (max-width: 49em) {
  /* 1rem = 7px */
  html {
    font-size: 43.75%;
  }
  .pricing-plan {
    width: 85%;
  }
}

/*************************************/
/* BELOW 688px (Smaller Tablets) */
/*************************************/

@media (max-width: 43em) {
  /* 1rem = 7px */
  /* html {
      font-size: 40%;
    } */

  .grid {
    display: grid !important;
    column-gap: 3.2rem !important;
    row-gap: 4.8rem !important;
  }

  .grid:not(:last-child) {
    margin-bottom: 4.8rem !important;
  }

  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .section-hero {
    // padding-top: 4.8rem !important;
    height: 100vh !important;
  }
  .section-advantages {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important;
    scroll-margin-top: 7rem !important;
  }
  .section-how {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important;
    scroll-margin-top: 10rem !important;
  }

  .diets {
    grid-column: 1/-1 !important;
    justify-self: center !important;
    text-align: center !important;
  }

  .diets .list {
    align-items: center !important;
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .heading-secondary {
    margin-bottom: 4.8rem !important;
  }

  .pricing-plan {
    width: 100% !important;
  }
  .grid--footer {
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .nav-col {
    grid-row: 1;
    grid-column: span 2 !important;
    margin-bottom: 3.2rem !important;
  }

  .logo-col,
  .address-col {
    grid-column: span 3 !important;
  }
}

/*************************************/
/* BELOW 544px (Mobile Phones) */
/*************************************/

@media (max-width: 34em) {
  .grid--2-cols,
  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: 1fr !important;
  }

  .section-hero {
    padding: 2.4rem 0 6.4rem 0 !important;
  }

  .hero {
    padding: 0 3.2rem !important;
  }

  .hero .btn,
  .hero .btn:link,
  .hero .btn:visited {
    padding: 2.4rem 1.6rem !important;
    font-size: 2.4rem !important;
    margin-bottom: 1.6rem !important;
  }

  .testimonial-container {
    padding: 9.6rem 4.8rem !important;
  }

  .testimonials {
    grid-template-columns: 1fr !important;
  }

  .section-advantages .container {
    justify-content: center !important;
  }

  .advantage {
    width: 90% !important;
    justify-self: center !important;
  }

  .hero-img {
    width: 90% !important;
  }
  .logos img {
    height: 1.6rem !important;
  }

  .pricing-plan {
    width: 90% !important;
    justify-self: center !important;
  }

  .step-img-box {
    transform: translateY(3.2rem) !important;
  }

  .step-img-box:nth-child(2) {
    grid-row: 1 !important;
  }
  .step-img-box:nth-child(6) {
    grid-row: 5 !important;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr) !important;
    gap: 1.2rem !important;
  }

  .cta {
    grid-template-columns: 1fr !important;
  }

  .cta-text-box {
    padding: 3.2rem !important;
  }
  .cta-img-box {
    height: 36rem !important;
    grid-row: 1 !important;
  }

  // .header {
  //   padding: 0 3.2rem !important;
  // }
}

/*************************************/
/* BELOW 360px (Mobile Phones) */
/*************************************/

@media (max-width: 22.5em) {
  .advantage {
    width: 80% !important;
  }
}

/*************************************/
/* Fixing flexbox gap in safari */
/*************************************/

.no-flexbox-gap .main-nav-list li:not(:last-child) {
  margin-right: 4.8rem;
}

.no-flexbox-gap .list-item:not(:last-child) {
  margin-bottom: 1.6rem;
}

.no-flexbox-gap .list-icon:not(:last-child) {
  margin-right: 1.6rem;
}

.no-flexbox-gap .delivered-faces {
  margin-right: 1.6rem;
}

.no-flexbox-gap .advantage-attribute:not(:last-child) {
  margin-bottom: 2rem;
}

.no-flexbox-gap .advantage-icon {
  margin-right: 1.6rem;
}

.no-flexbox-gap .footer-row div:not(:last-child) {
  margin-right: 6.4rem;
}

.no-flexbox-gap .social-links li:not(:last-child) {
  margin-right: 2.4rem;
}

.no-flexbox-gap .footer-nav li:not(:last-child) {
  margin-bottom: 2.4rem;
}

@media (max-width: 75em) {
  .no-flexbox-gap .main-nav-list li:not(:last-child) {
    margin-right: 3.2rem;
  }
}

@media (max-width: 59em) {
  .no-flexbox-gap .main-nav-list li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 4.8rem;
  }
}
// }

#directory {
  scroll-margin-top: 3.6rem;
}

.categories-container {
  max-width: 150rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  opacity: 0;
  transform: translateY(10%);
  transition: opacity 1s, transform 1s;
  &.slide-in {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-categories {
  // padding-bottom: 9.6rem;
  background-color: #f1f7fc;
  padding: 6.4rem;
  padding-top: 9.6rem;
  padding-bottom: 9.6rem;
  &.gender {
    .categories-container {
      // max-width: 100%;
      max-width: 120rem;
      gap: 6.4rem;
    }
  }
  &.category {
    .categories-container {
      gap: 4.8rem;
      @media screen and (max-width: 34em) {
        gap: 2.4rem;
      }
      // height: 40rem;
    }
  }
  .title-contanier-directory {
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@media (max-width: 34em) {
  .category {
    .categories-container {
      flex-direction: column;
      gap: 2.4rem;
      padding: 0 2.4rem;
      .directory-item-container {
        height: 25vh;
      }
    }
  }
  .gender {
    .categories-container {
      flex-direction: column;
      .directory-item-container {
        height: 30vh;
      }
    }
  }
}

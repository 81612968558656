#how {
  scroll-margin-top: 4rem;
  @media screen and (min-width: 135em) {
    scroll-margin-top: 14rem;
  }
}
.section-how {
  // background-image: url(../assets/bg/lllinet33.svg),
  //   url(../assets/bg/lllinet3.svg);
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: contain;
  padding: 9.6rem 0;
  @media screen and (min-width: 135em) {
    padding-top: 0;
  }

  .title-container {
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .step-number {
    font-size: 8.6rem;
    font-weight: 600;
    color: #d6e6f5;
    margin-bottom: 1.2rem;
  }

  .step-description {
    font-size: 1.8rem;
    color: #333;
    line-height: 1.8;
  }
  .step-text-box {
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      &.step-one {
        opacity: 1;
        transform: translateY(0);
      }
      &.step-two {
        opacity: 1;
        transform: translateY(0);
      }
      &.step-three {
        opacity: 1;
        transform: translateY(0);
      }
      &.step-four {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  .step-img-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      &.step-one {
        opacity: 1;
        transform: translateY(0);
      }
      &.step-two {
        opacity: 1;
        transform: translateY(0);
      }
      &.step-three {
        opacity: 1;
        transform: translateY(0);
      }
      &.step-four {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .step-img-box::before,
  .step-img-box::after {
    content: "";
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .step-img-box::before {
    width: 60%;
    padding-bottom: 60%;
    background-color: #f1f7fc;
    z-index: -2;
  }
  .step-img-box::after {
    width: 45%;
    padding-bottom: 45%;
    background-color: #d6e6f5;
    z-index: -1;
  }
  .step-img {
    width: 35%;
  }
  // .slideshow-img {
  //   width: 19rem;
  //   height: 39rem;
  // }
}

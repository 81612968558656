.video-responsive {
  overflow: hidden;
  // height: 0;
  height: 100%;
  width: 100%;

  border-radius: 2.4rem;
}
.iframe-youtube {
  border-radius: 2.4rem;
  height: 100%;
  width: 100%;
  max-width: 96rem;
  max-height: 55rem;
  overflow: hidden;
  aspect-ratio: 15.8/9;
  // aspect-ratio: 16/9;
  // max-width: 85rem;
  // max-height: 50rem;
  // box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.175);
}

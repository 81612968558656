.directory-item-container {
  min-width: 30%;
  height: 55rem;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid black;
  // margin: 0 7.5px 15px;
  overflow: hidden;
  // margin: 6.4rem;
  border-radius: 3.6rem;

  &.gender {
    height: 100rem;
    .directory-item-body-container {
      h2 {
        color: #0c3256;
      }
    }
  }

  cursor: pointer;
  &:hover {
    & .background-image {
      transform: scale(1.05);
      transition: transform 1s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }

    & .directory-item-body-container {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }

  &.large {
    height: 380px;
  }

  // &:first-child {
  //   margin-right: 7.5px;
  // }

  // &:last-child {
  //   margin-left: 7.5px;
  // }

  .background-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 1s;
    align-self: stretch;
    &.parkings {
      background-image: linear-gradient(
          to right bottom,
          rgb(173, 205, 236, 0.6),
          rgb(118, 171, 223, 0.6)
        ),
        url("../assets/webp/car1.webp");
      background-position: center;
    }
    &.desks {
      background-image: linear-gradient(
          to right bottom,
          rgb(173, 205, 236, 0.6),
          rgb(118, 171, 223, 0.6)
        ),
        url("../assets/webp/office-tall-4.webp");
    }
  }

  // .category-body-container {
  //   height: 90px;
  //   padding: 0 25px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   // border: 1px solid black;
  //   background-color: white;
  //   opacity: 0.7;
  //   position: absolute;
  //   transition: opacity 0.3s;

  //   h2 {
  //     font-weight: bold;
  //     margin: 0 6px 0;
  //     font-size: 22px;
  //     color: #4a4a4a;
  //     text-transform: uppercase;
  //   }

  //   p {
  //     font-weight: lighter;
  //     font-size: 16px;
  //   }
  // }
  .directory-item-body-container {
    height: 90px;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    // border: 1px solid black;
    // background-color: white;
    opacity: 0.8;
    position: absolute;
    transition: opacity 0.3s;

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    h2 {
      // font-weight: bold;
      margin: 0 6px 0;
      font-size: 4.8rem;
      color: #fff;
      // color: #0c3256;
      text-transform: uppercase;
    }

    p {
      font-weight: lighter;
      font-size: 16px;
    }
  }
}

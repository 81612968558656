.header {
  // background-color: #76abdf;
  position: absolute;
  width: 100%;

  /* STICKY NAV */
  &.sticky {
    // backdrop-filter: blur(10px) !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 9.6rem;
    padding-top: 0;
    padding-bottom: 0;
    background-color: rgb(118, 171, 223, 0.97);
    z-index: 999;
    // box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.075);
    // box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  }

  .nav-container {
    display: flex;
    @media (max-width: 60em) {
      display: none;
    }
    // overflow-x: hidden;
    background-color: transparent;
    height: 9.6rem;
    padding: 0 4.8rem 0 3.6rem;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media (max-width: 60em) {
      padding: 0 2.4rem 0 2rem;
    }
    .main-nav {
      z-index: 999;
    }
    .logo {
      height: 7.5rem;
    }
    .logo-link {
      cursor: pointer;
    }
    .logo-link:focus {
      outline: none;
      box-shadow: none;
    }
    .main-nav-list {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 4.8rem;
    }
    .main-nav-link,
    .main-nav-link:link,
    .main-nav-link:visited {
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      font-size: 1.8rem;
      color: #fff;
      font-weight: 600;
      transition: all 0.3s;
      // font-family: "SimplerPro", "Arial", Helvetica, sans-serif;
      @media (max-width: 60em) {
        color: #76abdf;
      }
    }

    .main-nav-link:not(.nav-cta) {
      box-shadow: none;
      transition: all ease-in-out 0.3s;
      &:hover {
        box-shadow: 0 2px 0 0 #f1f7fc;
        @media (max-width: 60em) {
          box-shadow: 0 2px 0 0 #76abdf;
        }
      }
      &.active {
        // border-bottom: 2px solid #f1f7fc;
        box-shadow: 0 2px 0 0 #f1f7fc;
        @media (max-width: 60em) {
          box-shadow: 0 2px 0 0 #76abdf;
        }
      }
    }

    .main-nav-link:hover,
    .main-nav-link:active {
      color: #adcdec;
    }
    .nav-cta-btn {
      font-size: 1.8rem;
      box-shadow: none;
      @media (max-width: 60em) {
        padding: 3.6rem 3.6rem;
        font-size: 2.8rem;
      }
    }

    .main-nav-link.nav-cta:hover,
    .main-nav-link.nav-cta:active {
      .nav-cta-btn {
        background-color: #adcdec;
      }
    }

    /* MOBILE */
    // .btn-mobile-nav {
    //   border: none;
    //   background: none;
    //   cursor: pointer;

    //   display: none;
    // }

    // .icon-mobile-nav {
    //   height: 4.8rem;
    //   width: 4.8rem;
    //   color: #fff;
    // }

    // .icon-mobile-nav[name="close-outline"] {
    //   display: none;
    // }
  }

  // .mobile-header {
  //   // background-color: #76abdf;
  //   position: absolute;
  //   width: 100%;

  /////////////////////////////
  .mobile-nav-container {
    display: none;
    @media (max-width: 60em) {
      display: block;
    }
    height: 9.6rem;
    // button {
    //   border: 0;
    //   padding: 0;
    //   background: transparent;
    //   cursor: pointer;
    // }

    .navbar-mobile,
    .navbar-burger,
    .menu,
    .background {
      position: fixed;
    }

    // .background {
    //   z-index: 1;
    //   top: -10%;
    //   left: -10%;
    //   width: 120%;
    //   height: 120%;
    //   background-image: url("assets/bg.jpeg");
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   transition: 0.5s;
    // }

    // &.open .background {
    //   filter: blur(20px);
    // }

    .navbar-mobile {
      z-index: 1;
      top: 0;
      left: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 9.6rem;
      padding-left: 2rem;
      padding-right: 0;
      // background: #19191c;
      // color: #f9f9f9;
    }

    .navbar-mobile > button {
      font-size: 28px;
    }

    .logo-link {
      .navbar-logo {
        height: 7rem;
      }
      height: 7rem;
      cursor: pointer;
    }

    .navbar-burger {
      z-index: 3;
      top: 0;
      right: 0;
      display: grid;
      place-items: center;
      width: 72px;
      height: 72px;
      background-image: url("../assets/menu.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
    .hamburger-container {
      position: absolute;
      z-index: 999;
      top: 50%;
      right: 1.2rem;
      transform: translateY(-50%);
      opacity: 1;
      visibility: visible;
      transition: all 0.3s;
      &.hidden {
        opacity: 0;
        visibility: hidden;
      }
      .hamburger-react {
        color: #f1f7fc;
        &:focus:not(:focus-visible) {
          outline: 0;
          box-shadow: none;
        }
        // &:hover {
        //   color: #114678;
        // }
      }
    }
    &.open .hamburger-container {
      .hamburger-react {
        color: #76abdf;
        // &:hover {
        //   color: #114678;
        // }
      }
    }

    &.open .navbar-burger {
      background-image: url("../assets/close.svg");
    }

    .menu {
      z-index: 2;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4.8rem;
      width: 100%;
      height: 100%;
      background: rgba(256, 256, 256, 0.8);
      backdrop-filter: blur(10px) !important;
      opacity: 0;
      visibility: hidden;
      transition: 0.5s;
    }

    // .main-nav-link,
    // .main-nav-link:link,
    // .main-nav-link:visited {
    //   cursor: pointer;
    //   display: inline-block;
    //   text-decoration: none;
    //   font-size: 1.8rem;
    //   color: #fff;
    //   font-weight: 600;
    //   transition: all 0.3s;
    //   // font-family: "SimplerPro", "Arial", Helvetica, sans-serif;
    //   @media (max-width: 60em) {
    //     color: #76abdf;
    //   }
    // }

    &.open .menu {
      opacity: 1;
      visibility: visible;
    }

    .menu > a {
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      font-size: 3rem;
      color: #fff;
      font-weight: 600;
      transition: all 0.3s;
      color: #76abdf;
    }
    .nav-cta-btn {
      font-size: 1.8rem;
      @media (max-width: 60em) {
        padding: 3.6rem 3.6rem;
        font-size: 2.8rem;
      }
    }

    .main-nav-link.nav-cta:hover,
    .main-nav-link.nav-cta:active {
      .nav-cta-btn {
        background-color: #adcdec;
      }
    }

    &.open .menu > a {
      animation: appear 0.3s both;
    }

    @keyframes appear {
      0% {
        opacity: 0;
        translate: 0 50px;
      }
      100% {
        opacity: 1;
      }
    }

    ///////////////////////////

    /* STICKY NAV */
    &.sticky {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 9.6rem;
      padding-top: 0;
      padding-bottom: 0;
      background-color: rgb(118, 171, 223, 0.97);
      z-index: 999;
      // box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.075);
      // box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    }

    .nav-container {
      display: flex;
      // overflow-x: hidden;
      background-color: transparent;
      height: 9.6rem;
      padding: 0 4.8rem 0 3.6rem;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @media (max-width: 60em) {
        padding: 0 2.4rem 0 2rem;
      }
      .main-nav {
        z-index: 999;
      }
      .logo {
        height: 6rem;
      }
      .logo-link {
        cursor: pointer;
      }
      .logo-link:focus {
        outline: none;
        box-shadow: none;
      }
      .main-nav-list {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 4.8rem;
      }
      .main-nav-link,
      .main-nav-link:link,
      .main-nav-link:visited {
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        font-size: 1.8rem;
        color: #fff;
        font-weight: 600;
        transition: all 0.3s;
        // font-family: "SimplerPro", "Arial", Helvetica, sans-serif;
        @media (max-width: 60em) {
          color: #76abdf;
        }
      }

      .main-nav-link:not(.nav-cta) {
        box-shadow: none;
        transition: all ease-in-out 0.3s;
        &:hover {
          box-shadow: 0 2px 0 0 #f1f7fc;
          @media (max-width: 60em) {
            box-shadow: 0 2px 0 0 #76abdf;
          }
        }
        &.active {
          // border-bottom: 2px solid #f1f7fc;
          box-shadow: 0 2px 0 0 #f1f7fc;
          @media (max-width: 60em) {
            box-shadow: 0 2px 0 0 #76abdf;
          }
        }
      }

      .main-nav-link:hover,
      .main-nav-link:active {
        color: #adcdec;
      }
      .nav-cta-btn {
        font-size: 1.8rem;
        @media (max-width: 60em) {
          padding: 3.6rem 3.6rem;
          font-size: 2.8rem;
        }
      }

      .main-nav-link.nav-cta:hover,
      .main-nav-link.nav-cta:active {
        .nav-cta-btn {
          background-color: #adcdec;
        }
      }

      /* MOBILE */
      .btn-mobile-nav {
        border: none;
        background: none;
        cursor: pointer;

        display: none;
      }

      .icon-mobile-nav {
        height: 4.8rem;
        width: 4.8rem;
        color: #fff;
      }

      .icon-mobile-nav[name="close-outline"] {
        display: none;
      }
    }
  }
}

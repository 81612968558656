.flip-card-outer {
  width: 25rem;
  height: 25rem;
  perspective: 1000px;
  margin: 25px 0;
  &:focus {
    outline: none !important;
    box-shadow: 0 0 0 0 #719ece !important;
  }
  @media (max-width: 25em) {
    width: 30rem;
    height: 30rem;
  }

  &.hover:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-inner {
    transform-style: preserve-3d;
    transition: 0.5s linear 0.1s;
    position: relative;
    width: inherit;
    height: inherit;

    .card {
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &.front {
        transform: rotateY(0);
      }

      &.back {
        transform: rotateY(180deg);
      }
    }
  }
}

#dp {
  scroll-margin-top: 4.8rem;
}
.section-dp {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .dp-container {
    // background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1.5fr;

    align-items: center;
    justify-items: center;
    padding: 3.6rem 3.6rem 11.2rem 3.6rem;
    @media screen and (min-width: 135em) {
      padding-bottom: 16.4rem;
    }
    @media screen and (max-width: 60em) {
      // height: 50rem;
      display: flex;
      flex-direction: column;
    }
    max-width: 150rem;
  }
  .dp-text-container {
    padding: 4.8rem 3.6rem;
    text-align: center;
    max-width: 100rem;
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .heading-secondary {
    margin-bottom: 3.6rem !important;
  }

  .dp-img {
    width: 6.4rem;
    border-radius: 50%;
    margin-bottom: 1.2rem;
  }

  .dp-text {
    font-size: 1.8rem;
    line-height: 1.8;
    margin-bottom: 1.6rem;
    color: #333;
  }
  .dp-name {
    font-size: 1.6rem;
    color: #6f6f6f;
  }
  img {
    box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.175);
    border-radius: 3.6rem;
    max-width: 80rem;
    width: 100%;
  }
}

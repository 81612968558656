.footer {
  padding: 6.8rem 0;
  border-top: 2px solid #f1f7fc;
  background-image: url(../assets/bg/layered-waves-haikei-footer.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .container {
    gap: 6.8rem;
  }

  .grid--footer {
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr;
  }

  .logo-col {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 17rem;
    .footer-logo {
      display: block;
      margin-top: -1rem;
      .logo {
        width: 18rem;
      }
    }
  }

  .social-copyright {
    align-items: flex-start;
  }

  .social-links {
    list-style: none;
    display: flex;
    gap: 2.4rem;
  }

  .social-icon {
    height: 2.4rem;
    width: 2.4rem;
  }

  .copyright {
    font-size: 1.4rem;
    line-height: 1.6;
    color: #767676;
    margin-top: auto;
  }

  .footer-heading {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 4rem;
    color: #333;
  }

  .contacts {
    font-style: normal;
    font-size: 1.6rem;
    line-height: 1.6;
  }

  .address {
    margin-bottom: 2.4rem;
    color: #333;
  }

  .nav-col {
    @media screen and (max-width: 29em) {
      display: none;
    }
    height: 100%;
    margin-bottom: 0 !important;

    .footer-nav {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      // justify-content: space-between;
      height: 100%;
      .footer-link {
        cursor: pointer;
      }
    }
  }
  .social-links {
    .footer-link {
      display: flex;
      padding: 0.5rem 0;
    }
  }
  .footer-link:link,
  .footer-link:visited {
    text-decoration: none;
    font-size: 1.6rem;
    color: #767676;
    transition: all 0.3s;
  }
  .footer-link:hover,
  .footer-link:active {
    text-decoration: none;
    font-size: 1.6rem;
    color: #476786;
  }
}

// .footer {
//   padding: 12.8rem 0;
//   border-top: 2px solid #f1f7fc;

//   .grid--footer {
//     grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr;
//   }

//   .logo-col {
//     display: flex;
//     flex-direction: column;
//     .footer-logo {
//       display: block;
//       margin-bottom: 3.2rem;
//       .logo {
//         width: 15rem;
//       }
//     }
//   }

//   .social-links {
//     list-style: none;
//     display: flex;
//     gap: 2.4rem;
//   }

//   .social-icon {
//     height: 2.4rem;
//     width: 2.4rem;
//   }

//   .copyright {
//     font-size: 1.4rem;
//     line-height: 1.6;
//     color: #767676;
//     margin-top: auto;
//   }

//   .footer-heading {
//     font-size: 1.8rem;
//     font-weight: 500;
//     margin-bottom: 4rem;
//     color: #333;
//   }

//   .contacts {
//     font-style: normal;
//     font-size: 1.6rem;
//     line-height: 1.6;
//   }

//   .address {
//     margin-bottom: 2.4rem;
//     color: #333;
//   }

//   .footer-nav {
//     list-style: none;
//     display: flex;
//     flex-direction: column;
//     gap: 2.4rem;
//   }

//   .footer-link:link,
//   .footer-link:visited {
//     text-decoration: none;
//     font-size: 1.6rem;
//     color: #767676;
//     transition: all 0.3s;
//   }
//   .footer-link:hover,
//   .footer-link:active {
//     text-decoration: none;
//     font-size: 1.6rem;
//     color: #476786;
//   }
// }

$accent-color: #76abdf;
$accent-color-tint: #adcdec;
$accent-color-shade: #476786;
$main-color: #333;
$sub-color: #777;

.button-container {
  min-width: 16.5rem;
  width: auto;
  height: 5rem;
  letter-spacing: 0.5px;
  line-height: 5rem;
  padding: 0 3.5rem 0 3.5rem;
  font-size: 1.5rem;
  background-color: $accent-color;
  color: white;
  text-transform: uppercase;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  border-radius: 40px;

  @media screen and (max-width: 34em) {
    // display: block;
    // opacity: 0.9;
    min-width: 10rem;
    // height: 4rem;
    padding: 0 0.2rem;
    font-size: 1.2rem;
  }
  &.disabled {
    background-color: #ddd !important;
    color: #aaa !important;
    cursor: auto !important;
    &:hover {
      background-color: #ddd !important;
      color: #aaa !important;
      box-shadow: none !important;
    }
  }

  &:hover {
    background-color: white;
    color: $accent-color;
    // border: 1px solid #333;
    box-shadow: 0 0 0 1px $accent-color;
  }

  &.google-sign-in {
    background-color: #4285f4;
    color: white;

    &:hover {
      background-color: #3970c9;
      border: none;
    }
  }

  &.inverted {
    background-color: white;
    color: $accent-color;
    // border: 1px solid $accent-color;
    box-shadow: 0 0 0 1px $accent-color;

    &:hover {
      background-color: $accent-color;
      color: white;
      // border: none;
      box-shadow: none;
    }
  }
  &.inverted-outlined {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    box-shadow: 0 0 0 1px $accent-color;

    &:hover {
      background-color: white;
      color: $accent-color;
      // border: none;
      box-shadow: none;
    }
  }

  &.no-border {
    border: none;
    box-shadow: none;
  }
}

.btn-spinner-container {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border: 3px solid rgba(195, 195, 195, 0.6);
  border-radius: 50%;
  border-top-color: #636767;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}

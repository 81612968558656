/*

Page container settings
  max-width: 150rem;
  padding: 0 9.6rem;

--- 01 Typography System ---

FONT STYLES
  Priamry: 'Playfair Display', serif;
  Secondary: 'Montserrat', sans-serif;

FONT SIZE SYSTEM (px):
 - 10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

Font weights:
 - Default: 400
 - Medium: 500
 - Semi-Bold: 600
 - Bold: 700

Line heights:
 - Default: 1
 - Small: 1.05
 - Medium: 1.2
 - Paragraph Degault: 1.6
 - Large: 1.8

Letter spacing:
 - -0.5px
 - 0.75px
 - 1px


--- 02 Colors ---

Primary: #76abdf
 - Tints: #91bce5 #adcdec #d6e6f5 #f1f7fc
 - Shades: #0c1116 #233343 #476786 #5e89b2
 Accents: 
 - Tints: 
 - Shades: 
GREYS: #333 #555 #777 #6f6f6f #767676 #aaa

--- 03 Transitions ---

  0.3s
  0.5s
  2s

--- 05 Shadows ---

--- 06 Border radius ---
 - Default: 9px
 - Medium: 11px

--- 07 Whitespace ---

SPACING SYSTEM (px):
 - 2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/
$accent-color: #0c3256;
$accent-color-tint: #114678;
$accent-color-shade: #071e33;
$main-color: #333;
$sub-color: #777;

/* Makes it so only keyboard focus works */
:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 10px; = 1 rem */
  /* 65.5% from users font size settings */
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  height: 100%;
  // .mobile-navigation {
  //   display: none;
  // }
}

body {
  position: absolute;
  width: 100%;
  height: 100%;
  // overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  font-family: "Roboto", sans-serif;
  // font-family: "Kanit", sans-serif;
  scroll-behavior: smooth;
}

// section {
//   padding-bottom: 9.6rem;
//   padding-right: 9.6rem;
//   padding-left: 9.6rem;
// }

.full-width {
  padding-left: 0;
  padding-right: 0;
}
.full-height {
  padding-top: 0;
  padding-bottom: 0;
}

/**************************/
/* BELOW 1200px (Big Tablets) */
/**************************/

@media (max-width: 75em) {
  // .mobile-navigation {
  //   display: block !important;
  //   width: 100%;
  //   height: 100%;
  // }
  // .big-navigation {
  //   display: none !important;
  // }
  html {
    /* font-size: 9px; = 1 rem */
    /* 65.5% from users font size settings */
    font-size: 56.25%;
  }
  .hero-img {
    height: 93vh !important;
  }
  .hero {
    left: 50% !important;
  }
  // section {
  //   padding-bottom: 9.6rem;
  //   padding-right: 4.8rem;
  //   padding-left: 4.8rem;
  // }
}

/**************************/
/* BELOW 720px (Small Tablets) */
/**************************/
@media screen and (max-width: 45em) {
  html {
    font-size: 50%;
  }
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  html {
    /* font-size: 7.2px; = 1 rem */
    /* 65.5% from users font size settings */
    font-size: 45%;
  }
  // section {
  //   padding-bottom: 6.4rem;
  //   padding-right: 2.4rem;
  //   padding-left: 2.4rem;
  // }
}

.hidden {
  display: none !important; // Checking if doestnt work return to navigation.scss and newsletter.scss
}

.scrollable::-webkit-scrollbar {
  width: 8px;
}

.scrollable::-webkit-scrollbar-track {
  background-color: #fff;
  width: 8px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border: 1px solid transparent;
  background-clip: content-box;
  border-radius: 10px;
  transition: all 0.3s;
}
.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #777;
  border: 1px solid transparent;
  background-clip: content-box;
  border-radius: 10px;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gap-s {
  gap: 1.4rem;
}
.gap-m {
  gap: 2.4rem;
}
.gap-l {
  gap: 3.6rem;
}

.center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.padding {
  padding: 2.4rem;
}
p::selection {
  background: #76abdf;
  color: #fff;
}
span::selection {
  background: #76abdf;
  color: #fff;
}
h1::selection {
  background: #76abdf;
  color: #fff;
}
h2::selection {
  background: #76abdf;
  color: #fff;
}
h3::selection {
  background: #76abdf;
  color: #fff;
}
div::selection {
  background: #76abdf;
  color: #fff;
}

.MuiDataGrid-menu {
  .MuiList-root {
    .MuiMenuItem-root {
      font-size: 1.4rem !important;
      .MuiTypography-root {
        font-size: 1.4rem !important;
      }
      .MuiSvgIcon-root {
        height: 1.8rem;
        width: 1.8rem;
      }
    }
  }
}
// .MuiList-root {
//   .MuiMenuItem-root {
//     font-size: 1.4rem !important;
//     .MuiTypography-root {
//       font-size: 1.4rem !important;
//     }
//     .MuiSvgIcon-root {
//       height: 1.8rem;
//       width: 1.8rem;
//     }
//   }
// }

.css-13tq9pv {
  // margin: 8.5px !important;
  padding: 8px;
}
.css-1xif2b4-MuiPopper-root-MuiDataGrid-menu {
  // margin: 8.5px !important;
  padding: 8px;
}
.MuiDataGrid-filterForm {
  // Fix MUI filter
  .MuiFormControl-root {
    font-size: 1.4rem !important;
    .MuiFormLabel-root {
      font-size: 2rem !important;
      // padding-bottom: 1rem !important;
    }
  }
  .MuiInputBase-root {
    font-size: 1.4rem !important;
    margin-top: 2.4rem;
  }
}
.MuiFormControlLabel-label {
  font-size: 1.4rem !important;
}
.MuiDataGrid-panelFooter {
  .MuiButton-textSizeMedium {
    font-size: 1.4rem !important;
  }
}
.MuiDateCalendar-root {
  // Fix MUI datepicker
  .MuiSvgIcon-root {
    width: 2rem !important;
    height: 2rem !important;
  }
  .MuiPickersCalendarHeader-label {
    font-size: 1.2rem !important;
  }
  .MuiDayCalendar-weekDayLabel {
    font-size: 1.2rem !important;
  }
  .MuiPickersDay-root {
    font-size: 1.2rem !important;
  }
  .MuiPickersYear-yearButton {
    font-size: 1.2rem !important;
  }
}
.MuiTooltip-tooltip {
  transform: scale(1.7) !important;
  // display: none;

  background-color: #76abdf !important;
  @media (max-width: 100em) {
    display: block !important;
  }
}
// .MuiTypography-root {
//   font-size: 1.4rem !important;
// }

.MuiTablePagination-menuItem {
  font-size: 1.2rem !important;
}
.tab-panel-container {
  .MuiBox-root {
    padding: 0 !important;
  }
}

.dialog-padding-top {
  padding-top: 3.6rem !important;
}

#platform {
  scroll-margin-top: 4.8rem;
}
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-platform {
  // background-color: #fff;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // align-items: center;
  // min-height: 50rem;
  // overflow: visible;
  // // height: 50rem;
  // // border-top: 2px solid #f1f7fc;
  // @media screen and (max-width: 60em) {
  //   // height: 50rem;
  //   display: flex;
  //   flex-direction: column;
  // }
  // max-width: 200rem;
  // background-image: url(../assets/bg/llline-4.svg),
  //   url(../assets/bg/llline-6.svg);
  background-image: url(../assets/bg/lllinet33.svg),
    url(../assets/bg/lllinet3.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .platform-container {
    // background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;

    align-items: center;
    justify-items: center;
    padding: 0 3.6rem 9.6rem 3.6rem;
    // min-height: 100rem;
    // overflow: hidden;
    // display: flex;
    // flex-direction: column;

    // height: 50rem;
    // border-top: 2px solid #f1f7fc;
    @media screen and (min-width: 135em) {
      padding-bottom: 16.4rem;
    }
    @media screen and (max-width: 60em) {
      // height: 50rem;
      display: flex;
      flex-direction: column;
    }
    max-width: 150rem;
    width: 100%;
  }
  .platform-text-container {
    padding: 4.8rem 3.6rem;
    text-align: center;
    max-width: 100rem;
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateY(0);
    }
    // animation: slideIn 1s ease-in;
  }

  .heading-secondary {
    margin-bottom: 3.6rem !important;
  }

  .platform-img {
    width: 6.4rem;
    border-radius: 50%;
    margin-bottom: 1.2rem;
  }

  .platform-text {
    font-size: 1.8rem;
    line-height: 1.8;
    margin-bottom: 1.6rem;
    color: #333;
  }
  .platform-name {
    font-size: 1.6rem;
    color: #6f6f6f;
  }
  .platform-img-container {
    box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.175);
    border-radius: 0.8rem;
    height: fit-content;
    background-color: transparent;
    background-image: url(../assets/webp/platform-screenshot-noshadow-test.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100rem;
    max-height: 55rem;
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  }
  img {
    box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.175);
    border-radius: 0.8rem;
    max-width: 100rem;
    width: 100%;
    // animation: slideIn 1s ease-in;
  }
  .platform-abilities {
    .platform-icon {
      color: #476786;
    }
  }
  .platform-flex-container {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: 6.4rem;
    width: 100%;
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 1s, transform 1s;
    &.slide-in {
      opacity: 1;
      transform: translateY(0);
      // animation: slideIn 1s ease-in;
    }
    .list {
      gap: 3.6rem;
      @media (max-width: 75em) {
        gap: 1.6rem;
      }
    }
  }
  @media (max-width: 40em) {
    .platform-flex-container {
      display: flex;
      flex-direction: column-reverse;
      .platform-abilities {
        .heading-tertiary {
          text-align: center;
        }
        .list {
          align-items: center;
        }
      }
    }
  }
}
